<script lang="ts">
	import { classes } from '$lib/utils';

	export let additionalClass = '';
</script>

<div
	class={classes(
		additionalClass,
		'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-start max-w-[80rem] gap-6'
	)}
>
	<slot />
</div>
